<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        v-model="editedItem.BrukerID"
        item-text="Description"
        item-value="Value"
        :items="BrukerItems"
        label="Bruker"
      ></v-autocomplete>
      <v-textarea
        v-model="editedItem.Dialog"
        filled
        label="Dialog"
        auto-grow
      ></v-textarea>
      <wtDatetime
        v-model="editedItem.OpprettetDato"
        label="Opprettet dato"
        onlyDate
      ></wtDatetime>
    </v-col>
  </v-row>
</template>
<script>
import KeyValue from "../global/KeyValue";
import wtDatetime from "../components/wtDatetime.vue";

export default {
  extends: KeyValue,
  name: "dlgDialog",
  components: {
    wtDatetime,
  },

  model: {
    prop: "editedItemIn",
    event: "input",
  },

  props: {
    keyTaskBehandlingID: Number,
    editedItemIn: Object,
    editMode: Number, //v-if="editMode !== -1" - Not in "new"
  },

  computed: {
    editedItem() {
      return this.editedItemIn
    }
  },

  created() {
    this.GetBrukerCode();
  },

  methods: {
    output: function () {
      // pass back to the parent
      this.$emit("input", this.editedItem);
    },
  },
};
</script>
